
            @import 'src/styles/mixins.scss';
        
.header {
    @include handleAllBreakpoints(
        "background-color",
        "backgroundColor",
        "var(--global-headerAndFooter-header-background-color, #fff)"
    );
    @include handleAllBreakpoints("color", "textColor", "var(--global-headerAndFooter-header-text-color, #000)");
    @include handleAllBreakpoints("font-weight", "textWeight", "400");
    @include handleAllBreakpoints(
        "border-bottom",
        "bottomBorder",
        "var(--global-headerAndFooter-header-bottom-border, none)"
    );
    @include handleAllBreakpoints("box-shadow", "boxShadow", "var(--global-headerAndFooter-header-box-shadow, none)");

    .logo {
        @include handleAllBreakpoints("max-width", "logoMaxWidth", "200px::250px");
        @include handleAllBreakpoints("filter", "logoFilter", "unset");
    }

    .banner {
        @include handleAllBreakpoints(
            "background-color",
            "bannerBackgroundColor",
            "var(--banner-background-color, #ccc)"
        );
        @include handleAllBreakpoints("color", "bannerTextColor", "var(--banner-text-color, #000)");
        // padding: 10px;
        text-align: center;

        display: grid;
        grid-template-rows: 0fr;
        transition: 250ms all ease;
        position: relative;
        overflow: hidden;
        padding-inline: 25px;

        .close-button {
            @apply absolute  border-0 outline-none cursor-pointer bg-transparent flex items-center justify-center;
            width: 20px;
            height: 20px;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
            svg {
                width: 12px;
                height: 12px;
                fill: black;
                @include handleAllBreakpoints("fill", "bannerTextColor", "var(--banner-text-color, #000)");
                @include lg {
                    width: 17px;
                    height: 17px;
                }
            }
            @include lg {
                right: 20px;
            }
        }
    }

    .banner.active {
        grid-template-rows: 1fr;
        padding-block: 10px;
    }

    .banner-inner {
        overflow: hidden;
    }
}
